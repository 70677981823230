<template>
  <div class="tags">
    <div class="tags__nav">
      <p class="tags__nav-item" :class="{active: activeTag === 'special_offers'}"  @click="toggleItem('special_offers')">SPECIAL OFFERS</p>
      <p class="tags__nav-item" :class="{active: activeTag === 'latest'}"  @click="toggleItem('latest')">LATEST</p>
      <p class="tags__nav-item" :class="{active: activeTag === 'featured'}"  @click="toggleItem('featured')">FEATURED</p>
    </div>
    <Swiper
      :modules="[Autoplay]"
      :autoplay="{
        delay: 5000,
        disableOnInteraction: false,
      }"
      :breakpoints="{
        200: {
          slidesPerView: 1
        },
        700: {
          slidesPerView: 2
        },
        1000: {
          slidesPerView: 3
        },
        1300: {
          slidesPerView: 4
        },
        1680: {
          slidesPerView: 5
        }
      }"
      :loop="true"
      :speed="1000"
      class="swiper"
    >
      <Swiper-slide v-for="product in products" :key="product.node.uuid">
        <product-card
          v-if="products.length > 0"
          :product="product.node"
          :width="250"
        />
        <product-card-skeleton
          v-for="idx in 5"
          v-else
        />
      </Swiper-slide>
    </Swiper>
  </div>
</template>

<script setup>
import {onMounted, reactive, ref, watch} from "vue";
import {Autoplay} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/vue";
import 'swiper/css';
import ProductCard from "@/components/product/product-card.vue";
import {Products} from "@/core/api/products.js";
import ProductCardSkeleton from "@/components/skeletons/product-card-skeleton.vue";

const products = ref([])
const activeTag = ref('special_offers')

const toggleItem = (itemName) => {
  activeTag.value = itemName
}

const getProductByTag = async () => {
  products.value = []
  const res = await Products.getProductByTag(activeTag.value)
  products.value = res.data.products.edges
}

watch(
  () => activeTag.value,
  async () => {
    await getProductByTag()
  },
  { deep: true }
)

onMounted(async () => {
  await getProductByTag()
})
</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.tags {
  background-color: #ebebeb;
  padding: 100px;

  &__nav {
    display: flex;


    &-item {
      position: relative;
      cursor: pointer;
      font-size: 14px;
      font-weight: 700;
      padding: 0 10px 8px 10px;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 3px;
        width: 0;
        transition: all .2s ease;
        background-color: rgba(143, 210, 238, 1);
      }
      &.active:after {
        width: 100%;
      }
    }
  }
}

.swiper {
  margin-top: 30px;
}

:deep(.swiper-slide) {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 550px) {
  .tags {
    padding: 30px;
  }
}
</style>