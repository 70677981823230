<template>
  <div class="card">
    <img v-if="!isInteger(product.price)" class="card__sale" src="@images/sale.png" alt="sale">
    <div class="card__inner" :class="[{card__gradient: !isInteger(product.price)}]" :style="{ width: `${width}px` }">
      <div class="card__wrapper">
        <div class="card__block">
          <router-link class="card__link" :to="({ name: 'product-page', params: { productId: product?.uuid } })">
            <img class="card__block-image" :src="product?.images?.edges[0]?.node.image" :alt="product.name">
          </router-link>
          <div class="card__block-quickview" @click="goToDetails(product.uuid)">
            <img src="@icons/quickview.svg" alt="quickview">
          </div>
        </div>
        <p class="card__name">{{ product?.name }}</p>
        <p class="card__price">{{ product?.price }}€</p>
        <div class="card__line"></div>
        <div class="card__actions">
          <button class="card__button" @click="goToAttributes(product)">Add to Cart</button>
          <div class="card__wishlist">
            <svg @click="addProductToWishlist" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M12 6.00019C10.2006 3.90317 7.19377 3.2551 4.93923 5.17534C2.68468 7.09558 2.36727 10.3061 4.13778 12.5772C5.60984 14.4654 10.0648 18.4479 11.5249 19.7369C11.6882 19.8811 11.7699 19.9532 11.8652 19.9815C11.9483 20.0062 12.0393 20.0062 12.1225 19.9815C12.2178 19.9532 12.2994 19.8811 12.4628 19.7369C13.9229 18.4479 18.3778 14.4654 19.8499 12.5772C21.6204 10.3061 21.3417 7.07538 19.0484 5.17534C16.7551 3.2753 13.7994 3.90317 12 6.00019Z"
                  stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useAppStore} from "@/stores/app.js";
import {useWishlistStore} from "@/stores/wishlist.js";
import {useAuthStore} from "@/stores/auth.js";
import {computed} from "vue";
import {Wishlist} from "@/core/api/wishlist.js";
import {useAuthModalsStore} from "@/stores/authModals.js";
import {isInteger} from "lodash";

const appStore = useAppStore()
const authModalsStore = useAuthModalsStore()
const authStore = useAuthStore()
const wishlistStore = useWishlistStore()

const props = defineProps({
  width: Number,
  product: Object
})

const user = computed(() => authStore.user)
const accessToken = computed(() => {
  return authStore.accessToken ? authStore.accessToken : null
})

const goToDetails = (id) => {
  appStore.setActiveProductId(id)
  appStore.setProductDetailsVisible(true)
}

const goToAttributes = (product) => {
  if (user.value) {
    appStore.setActiveProductAttributes(product)
    appStore.setProductAttributesVisible(true)
  } else {
     authModalsStore.setSignIn(true)
  }
}

const addProductToWishlist = async () => {
  if (user.value) {
    const res = await Wishlist.overwriteWishlist("addProductToWishlist", props.product.uuid, wishlistStore.wishlist.uuid, accessToken.value)

    if (res.data.addProductToWishlist.wishlist) {
      wishlistStore.wishlist = res.data.addProductToWishlist.wishlist

      const popup = {
        text: 'You have successfully added the product to wishlist!',
        tag: 'success',
        id: Math.random() * 2000
      }
      appStore.popupArray.unshift(popup)
      appStore.setPopupVisible(true)
    } else {
      const popup = {
        text: 'Something went wrong!',
        tag: 'fail',
        id: Math.random() * 2000
      }
      appStore.popupArray.unshift(popup)
      appStore.setPopupVisible(true)
    }
  } else {
     authModalsStore.setSignIn(true)
  }
}
</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

.card {
  position: relative;

  &__inner {
    background: rgba(255, 255, 255, 1);
    border: 2px solid rgba(221, 221, 221, 1);
    border-radius: 10%;
    padding: 6px;
    transition: 0.2s;
    display: flex;
    flex-direction: column;
    gap: 7px;

    &:hover {
      background-color: rgba(225, 238, 250, 1);
    }
  }

  &__gradient {
    overflow: hidden;
    position: relative;
    z-index: 1;
    border: none !important;

    &::after {
      content: "";
      position: absolute;
      top: -25%;
      left: -25%;
      width: 500px;
      height: 500px;
      background: linear-gradient(225deg, #F4E87A 0%, #BCDBA9 23%, #8ED1CA 44%, #97BDDB 62%, #C19DDD 84%, #F478DE 100%);
      z-index: -1;
      animation: rotate 3s linear infinite;
    }
  }

  &__wrapper {
    border-radius: 10%;
    background-color: rgba(255, 255, 255);
    padding: 5px;
  }

  &__sale {
    position: absolute;
    top: -25px;
    right: 25px;
    z-index: 2;
  }

  &__link {
    display: inline-block;
    height: 236px;
    width: 100%;
  }

  &__block {
    position: relative;

    &-image {
      cursor: pointer;
      width: 100%;
      border-radius: 12%;
    }

    &:hover .card__block-quickview {
      visibility: visible;
    }

    &-quickview {
      visibility: hidden;
      cursor: pointer;
      padding: 2px 4px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: $blue;
      border-radius: 2px;
      display: grid;
      place-items: center;
      transition: 0.2s;

      &:hover {
        background-color: $orange;
      }
    }
  }

  &__name {
    padding-left: 8px;
    font-size: 14px;
    color: #337ab7;
  }

  &__price {
    padding-left: 8px;
    font-size: 12px;
    color: $dark-blue;
  }

  &__line {
    background-color: rgba(221, 221, 221, 1);
    height: 1px;
    width: 100%;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 10px;
  }

  &__wishlist {
    & svg {
      cursor: pointer;
      width: 17px;
    }
  }

  &__button {
    cursor: pointer;
    background-color: $blue;
    border-radius: 2px;
    padding: 5px 10px;
    transition: 0.2s;

    color: $white;
    font-size: 12px;
    font-weight: 700;

    &:hover {
      background-color: rgba(248, 86, 57, 1);
    }
  }
}
</style>