<template>
  <header class="mobile">
    <div class="mobile__top">
      <div class="container">
        <div class="mobile__top-wrapper">
          <router-link v-if="user" :to="({ name: 'profile' })" class="mobile__top-item">
            <svg width="13" height="13" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Dribbble-Light-Preview" transform="translate(-420.000000, -2159.000000)" fill="#6d767d">
                  <g id="icons" transform="translate(56.000000, 160.000000)">
                    <path d="M374,2009 C371.794,2009 370,2007.206 370,2005 C370,2002.794 371.794,2001 374,2001 C376.206,2001 378,2002.794 378,2005 C378,2007.206 376.206,2009 374,2009 M377.758,2009.673 C379.124,2008.574 380,2006.89 380,2005 C380,2001.686 377.314,1999 374,1999 C370.686,1999 368,2001.686 368,2005 C368,2006.89 368.876,2008.574 370.242,2009.673 C366.583,2011.048 364,2014.445 364,2019 L366,2019 C366,2014 369.589,2011 374,2011 C378.411,2011 382,2014 382,2019 L384,2019 C384,2014.445 381.417,2011.048 377.758,2009.673"
                      id="profile-[#1335]">
                    </path>
                  </g>
                </g>
              </g>
            </svg>
            ACCOUNT
          </router-link>
          <div v-else class="mobile__top-item" @click="authModalsStore.setSignIn(true)">
            <svg width="13" height="13" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Dribbble-Light-Preview" transform="translate(-420.000000, -2159.000000)" fill="#6d767d">
                  <g id="icons" transform="translate(56.000000, 160.000000)">
                    <path d="M374,2009 C371.794,2009 370,2007.206 370,2005 C370,2002.794 371.794,2001 374,2001 C376.206,2001 378,2002.794 378,2005 C378,2007.206 376.206,2009 374,2009 M377.758,2009.673 C379.124,2008.574 380,2006.89 380,2005 C380,2001.686 377.314,1999 374,1999 C370.686,1999 368,2001.686 368,2005 C368,2006.89 368.876,2008.574 370.242,2009.673 C366.583,2011.048 364,2014.445 364,2019 L366,2019 C366,2014 369.589,2011 374,2011 C378.411,2011 382,2014 382,2019 L384,2019 C384,2014.445 381.417,2011.048 377.758,2009.673"
                      id="profile-[#1335]">
                    </path>
                  </g>
                </g>
              </g>
            </svg>
            LOGIN
          </div>
          <div v-if="user" class="mobile__top-item" @click="logout">
            <svg width="20" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="12" cy="9" r="3" stroke="#6d767d" stroke-width="1.5"/>
              <circle cx="12" cy="12" r="10" stroke="#6d767d" stroke-width="1.5"/>
              <path d="M17.9691 20C17.81 17.1085 16.9247 15 11.9999 15C7.07521 15 6.18991 17.1085 6.03076 20" stroke="#6d767d"
                  stroke-width="1.5" stroke-linecap="round"/>
            </svg>
            SIGN OUT
          </div>
          <div v-else class="mobile__top-item" @click="authModalsStore.setSignUp(true)">
            <svg fill="#6d767d" height="13" width="13" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 310 310" xml:space="preserve">
            <path d="M300.606,159.727l-45.333-45.333c-5.857-5.858-15.355-5.858-21.213,0L225,123.454V15c0-8.284-6.716-15-15-15H20
              C11.716,0,5,6.716,5,15v240.002c0,8.284,6.716,15,15,15h85V295c0,8.284,6.716,15,15,15h45.333c3.979,0,7.794-1.581,10.607-4.394
              l124.667-124.667C306.465,175.081,306.465,165.585,300.606,159.727z M35,30h160v123.454l-85.606,85.605
              c-0.302,0.301-0.581,0.619-0.854,0.942H35V30z M159.12,280H135v-24.121l109.667-109.666l24.12,24.12L159.12,280z"/>
            </svg>
            REGISTER
          </div>
          <router-link to="/contact" class="mobile__top-item">
            <svg width="13" height="13" viewBox="0 -4 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"
               xmlns:sketch="http://www.bohemiancoding.com/sketch/ns">
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
                <g id="Icon-Set" sketch:type="MSLayerGroup" transform="translate(-412.000000, -259.000000)" fill="#6d767d">
                  <path d="M442,279 C442,279.203 441.961,279.395 441.905,279.578 L433,270 L442,263 L442,279 L442,279 Z M415.556,280.946 L424.58,271.33 L428,273.915 L431.272,271.314 L440.444,280.946 C440.301,280.979 415.699,280.979 415.556,280.946 L415.556,280.946 Z M414,279 L414,263 L423,270 L414.095,279.578 C414.039,279.395 414,279.203 414,279 L414,279 Z M441,261 L428,271 L415,261 L441,261 L441,261 Z M440,259 L416,259 C413.791,259 412,260.791 412,263 L412,279 C412,281.209 413.791,283 416,283 L440,283 C442.209,283 444,281.209 444,279 L444,263 C444,260.791 442.209,259 440,259 L440,259 Z"
                    id="mail" sketch:type="MSShapeGroup">
                  </path>
                </g>
              </g>
            </svg>
            CONTACT
          </router-link>
<!--          <router-link to="/blog" class="mobile__top-item">-->
<!--            <img src="@icons/messageIcon.svg" alt="blog">-->
<!--            BLOG-->
<!--          </router-link>-->
        </div>
      </div>
    </div>
    <div class="mobile__bottom">
      <div class="container">
        <div class="mobile__bottom-wrapper">
          <router-link :to="({ name: 'home' })">
            <img src="@icons/logo.svg" alt="logo">
          </router-link>
          <div class="mobile__bottom-actions">
            <svg @click="dropdown = true" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 6H20M4 12H20M4 18H20" stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <router-link to="/wishlist" class="mobile__bottom-block" v-if="user">
              <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M12 6.00019C10.2006 3.90317 7.19377 3.2551 4.93923 5.17534C2.68468 7.09558 2.36727 10.3061 4.13778 12.5772C5.60984 14.4654 10.0648 18.4479 11.5249 19.7369C11.6882 19.8811 11.7699 19.9532 11.8652 19.9815C11.9483 20.0062 12.0393 20.0062 12.1225 19.9815C12.2178 19.9532 12.2994 19.8811 12.4628 19.7369C13.9229 18.4479 18.3778 14.4654 19.8499 12.5772C21.6204 10.3061 21.3417 7.07538 19.0484 5.17534C16.7551 3.2753 13.7994 3.90317 12 6.00019Z"
                  stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <div class="mobile__bottom-quantity">{{ itemsInWishlist }}</div>
            </router-link>
            <svg class="search__icon" width="30" height="56" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M17.0392 15.6244C18.2714 14.084 19.0082 12.1301 19.0082 10.0041C19.0082 5.03127 14.9769 1 10.0041 1C5.03127 1 1 5.03127 1 10.0041C1 14.9769 5.03127 19.0082 10.0041 19.0082C12.1301 19.0082 14.084 18.2714 15.6244 17.0392L21.2921 22.707C21.6828 23.0977 22.3163 23.0977 22.707 22.707C23.0977 22.3163 23.0977 21.6828 22.707 21.2921L17.0392 15.6244ZM10.0041 17.0173C6.1308 17.0173 2.99087 13.8774 2.99087 10.0041C2.99087 6.1308 6.1308 2.99087 10.0041 2.99087C13.8774 2.99087 17.0173 6.1308 17.0173 10.0041C17.0173 13.8774 13.8774 17.0173 10.0041 17.0173Z"
                fill="#000"/>
            </svg>
            <div class="search">
              <div class="search__button">
                {{ selectedCategoryForSearch === '' ? 'All' : selectedCategoryForSearch}}
                <img class="search__arrow" src="@icons/arrowForSearch.svg" alt="arrow">
              </div>
              <div class="search__categories" v-if="categoriesForSearch.length > 1">
                <p
                    v-for="category in categoriesForSearch"
                    :key="category"
                    @click="selectCategoryForSearch(category)"
                >
                  {{ category ? category : 'All' }}
                </p>
              </div>
              <input
                class="search__input"
                placeholder="Search here..."
                v-model="search"
                type="text"
              >
              <img src="@icons/searchIcon.svg" alt="search">
              <div class="search__dropdown" v-if="searchDropdown" ref="searchDropdownRef">
                <div
                    @click="rediretToProduct(product.node.uuid)"
                    :key="product.node.uuid"
                    class="search__item"
                    v-for="product in searchProducts"
                    v-if="!isLoading && searchProducts.length"
                >
                  <img class="search__item-img" :src="product.node.images.edges[0].node.image" :alt="product.node.name">
                  <div class="search__inner">
                    <p class="search__name">{{ product.node.name }}</p>
                    <p class="search__price">{{ product.node.price }}€</p>
                  </div>
                </div>
                <p v-if="!isLoading && !searchProducts.length" class="header__search-name">No results found.</p>
              </div>
            </div>
            <router-link to="/cart" class="mobile__bottom-block" v-if="user">
              <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.78571 5H18.2251C19.5903 5 20.5542 6.33739 20.1225 7.63246L18.4558 12.6325C18.1836 13.4491 17.4193 14 16.5585 14H6.07142M4.78571 5L4.74531 4.71716C4.60455 3.73186 3.76071 3 2.76541 3H2M4.78571 5L6.07142 14M6.07142 14L6.25469 15.2828C6.39545 16.2681 7.23929 17 8.23459 17H17M17 17C15.8954 17 15 17.8954 15 19C15 20.1046 15.8954 21 17 21C18.1046 21 19 20.1046 19 19C19 17.8954 18.1046 17 17 17ZM11 19C11 20.1046 10.1046 21 9 21C7.89543 21 7 20.1046 7 19C7 17.8954 7.89543 17 9 17C10.1046 17 11 17.8954 11 19Z"
                  stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <div class="mobile__bottom-quantity">{{ itemsInCart }}</div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <Transition name="slide-fade">
      <dropdown-menu @close-modal="handleCloseModal" :categories="categories" v-if="dropdown" />
    </Transition>
  </header>
</template>

<script setup>
import {useAuthModalsStore} from "@/stores/authModals.js";
import {computed, onMounted, ref, watch} from "vue";
import {useAuthStore} from "@/stores/auth.js";
import {useWishlistStore} from "@/stores/wishlist.js";
import {useCartStore} from "@/stores/cart.js";
import {Categories} from "@/core/api/categories.js";
import DropdownMenu from "@/components/base/dropdown-menu.vue";
import {useRouter} from "vue-router";
import {onClickOutside} from "@vueuse/core";
import {Products} from "@/core/api/products.js";

const router = useRouter()

const authModalsStore = useAuthModalsStore()
const authStore = useAuthStore()
const wishlistStore = useWishlistStore()
const cartStore = useCartStore()

const user = computed(() => authStore.user)
const itemsInWishlist = computed(() => {
  return wishlistStore.wishlist.products ? wishlistStore.wishlist.products.edges.length : 0
})
const itemsInCart = computed(() => {
  let res = 0
  cartStore.currentOrder.orderProducts?.edges.map((item) => {
    res += item.node.quantity
  })
  return res
})

const categories = ref([])
const dropdown = ref(false)
const handleCloseModal = () => {
    dropdown.value = false
}

const searchDropdown = ref(false)
const searchDropdownRef = ref(null)
onClickOutside(searchDropdownRef, () => searchDropdown.value = false)

const categoriesForSearch = [
  ""
]
const selectedCategoryForSearch = ref('')
const selectCategoryForSearch = (category) => {
  selectedCategoryForSearch.value = category
}

const rediretToProduct = (id) => {
  router.push({
    name: 'product-page',
    params: { productId: id }
  })
  searchDropdown.value = false
}

const search = ref('')
const searchProducts = ref([])
const isLoading = ref(false)

const logout = async () => {
  await router.push({ name: 'home' })

  authStore.setUser({
    user: null,
    accessToken: null
  })

  localStorage.removeItem('stz-refresh')
}

const getCategories = async () => {
  const res = await Categories.getAllCategories()
  categories.value = res.data.categories.edges
  res.data.categories.edges.map((category) => categoriesForSearch.push(category.node.name))
}

const getProductsFromSearch = async () => {
  isLoading.value = true

  const res = await Products.getAllProductsFromSearch(0, selectedCategoryForSearch.value, search.value)
  searchProducts.value = res.data.products.edges
  searchDropdown.value = true

  isLoading.value = false
}

watch(
  () => search.value,
  async () => {
    await getProductsFromSearch()
  },
  { deep: true }
)
watch(
  () => selectedCategoryForSearch.value,
  async () => {
    await getProductsFromSearch()
  },
  { deep: true }
)

onMounted(async () => {
  await getCategories()
})
</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.slide-fade-enter-active {
    transition: all 0.3s ease-out;
}
.slide-fade-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(-20px);
    opacity: 0;
}

.mobile {
  display: none;

  &__top {
    padding-block: 15px;
    background-color: $light-blue;

    &-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }

    &-item {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 5px;

      color: rgba(109, 118, 125, 1);
      font-size: 11px;
      font-weight: 700;
    }
  }

  &__bottom {
    position: relative;
    background-color: $white;

    &-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & img {
        width: 105px;
      }
    }

    &-actions {
      display: flex;
      align-items: center;
      gap: 15px;

      & svg {
        cursor: pointer;
      }
    }

    &-block {
      position: relative;
    }

    &-quantity {
      position: absolute;
      top: -8px;
      right: -8px;
      background-color: $red;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      display: grid;
      place-items: center;

      color: $white;
      font-size: 10px;
    }
  }
}

.search {
  display: none;
  position: absolute;
  z-index: 2;
  top: 50px;
  right: 0;
  left: 0;
  width: 100vw;
  height: 55px;
  align-items: center;
  padding: 8px;
  background: rgba(187, 198, 221, 1);

  &:hover {
    display: flex;
  }

  & img {
    width: 24px;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
  }

  &__icon {
    &:hover ~ .search {
      display: flex;
    }
  }

  &__button {
    cursor: pointer;
    background-color: rgba(225, 238, 250, 1);
    width: fit-content;
    height: 100%;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0;
    padding-top: 3px;
    padding-inline: 5px;
    transition: 0.2s;

    color: rgb(109, 118, 125);
    font-size: 16px;

    &:hover ~ .search__categories {
      display: flex;
    }
  }

  &__arrow {
    width: 15px !important;
    margin-bottom: 3px;
    position: unset !important;
    transform: unset !important;
  }

  &__categories {
    padding-top: 5px;
    display: none;
    position: absolute;
    top: 45px;
    z-index: 3;
    box-shadow: 0 0 85px -10px rgba(0, 0, 0, 0.2);
    max-height: 350px;
    width: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 2px;
    flex-direction: column;
    align-items: center;

    &:hover {
      display: flex;
    }

    & p {
      cursor: pointer;
      background-color: #ffffff;
      border-bottom: 1px solid rgb(245, 245, 245);
      padding: 11px;
      width: 100%;
      transition: 0.2s;
      font-size: 14px;
      font-weight: 500;
      color: rgb(35, 56, 81);
    }
  }

  &__input {
    width: 100%;
    height: 100%;
    background-color: rgba(225, 238, 250, 1);
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    padding-inline: 12px;
    font-size: 16px;
    color: #233851;
  }

  &__dropdown {
    position: absolute;
    right: 0;
    left: 0;
    z-index: 2;
    top: 50px;
    width: 100%;
    box-shadow: 0 5px 60px -10px rgba(0, 0, 0, 0.3);
    border: 1px solid rgb(245, 245, 245);
    border-radius: 2px;
    max-height: 350px;
    overflow-y: auto;
    background-color: #ffffff;
    margin-left: auto;
  }

  &__item {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 15px;
    padding-block: 5px;
    border-bottom: 1px solid rgb(238, 238, 238);
    background-color: #ffffff;
    transition: 0.2s;

    &:hover {
      background-color: rgb(245, 245, 245);
    }

    &-img {
      position: unset !important;
      transform: unset !important;
      width: 60px !important;
      aspect-ratio: 1;
    }
  }

  &__name {
    font-size: 15px;
    font-weight: 700;
    color: #444;
  }

  &__price {
    color: #555;
    font-size: 14px;
  }
}

@media (max-width: 900px) {
  .mobile {
    display: block;
  }
}
</style>