<template>
  <div class="page">
    <h1 class="page__title">Privacy policy</h1>
    <div class="container">
      <div class="page__wrapper">
        <div class="page__block">
          <p class="page__subtitle">Privacy Statement Overview</p>
          <p class="page__text">This Privacy Statement explains how {{ companyName }} handles personal data and information, emphasizing our commitment to your privacy. We may update this policy periodically, and we recommend that visitors and users check it regularly.</p>
        </div>
        <div class="page__block">
          <p class="page__subtitle">Personal Information Defined</p>
          <p class="page__text">"Personal Information" refers to any data that can identify, contact, or locate an individual. All such data we collect will be governed by this Privacy Policy, which may change over time. To modify your information and preferences, please refer to the section titled "Updating Your Personal Information and Privacy Preferences."</p>
        </div>
        <div class="page__block">
          <p class="page__subtitle">Collection of Personal Information</p>
          <p class="page__text">As a site visitor, you can participate in many activities without sharing personal information. However, when you register on a sticker-zone.com site or order products/services as a subscriber, we may collect the following:</p>
          <ul class="page__ul">
            <li class="page__text">Contact details: name, phone number, address, and email.</li>
            <li class="page__text">Billing information: credit card number, expiration date, card type, and billing address.</li>
            <li class="page__text">Profile information: username, password, purchase details, and licensed content.</li>
          </ul>
          <p class="page__text">If you submit content for licensing on sticker-zone.com, you may need to provide:</p>
          <ul class="page__ul">
            <li class="page__text">Contact details: name, phone number, primary address, and email.</li>
            <li class="page__text">Payment and contributor information: payout method, payout email, display name, content focus, and details about your skills and creative process.</li>
          </ul>
          <p class="page__text">Certain information is mandatory for specific activities; failure to provide it may prevent your participation.</p>
          <p class="page__text">{{ companyName }} or its third-party subprocessors may collect technical information about your computer to enhance your experience on the site. This includes browser type, operating system, CPU speed, and IP address, which helps us analyze traffic patterns and improve our services.</p>
        </div>
        <div class="page__block">
          <p class="page__subtitle">Updating Your Personal Information and Privacy Preferences</p>
          <p class="page__text">Upon request, {{ companyName }} will provide access to your Personal Information. To request this, contact us at <a class="page__link" :href="'mailto:' + companyEmail">{{ companyEmail }}</a>. You can access and update your Personal Information at any time by clicking "My Account." To delete your account or stop using your information, email us at <a class="page__link" :href="'mailto:' + companyEmail">{{ companyEmail }}</a>.</p>
        </div>
        <div class="page__block">
          <p class="page__subtitle">Usage of Personal Information</p>
          <p class="page__text">{{ companyName }} collects your information to provide services, comply with legal obligations, and enhance our offerings. We do not sell or share your personal information with third parties except as outlined in this policy. Uses of your information include:</p>
          <ul class="page__ul">
            <li class="page__text">Authenticating access to our websites and services.</li>
            <li class="page__text">Processing financial transactions.</li>
            <li class="page__text">Sending order confirmations.</li>
            <li class="page__text">Providing technical support rights.</li>
            <li class="page__text">Addressing customer service inquiries.</li>
            <li class="page__text">Managing your account.</li>
            <li class="page__text">Informing you about promotions and services.</li>
            <li class="page__text">Conducting research and improving our offerings.</li>
          </ul>
          <p class="page__text">In some circumstances, we may need to disclose personal data to comply with legal requests from public authorities.</p>
        </div>
        <div class="page__block">
          <p class="page__subtitle">Legal Bases for Processing Data</p>
          <p class="page__text">For individuals in the European Economic Area (EEA) and the UK, we rely on specific legal bases for processing your personal data, such as:</p>
          <ul class="page__ul">
            <li class="page__text">Fulfilling our contract with you.</li>
            <li class="page__text">Protecting your vital interests.</li>
            <li class="page__text">Advancing {{ companyName }}'s legitimate interests.</li>
            <li class="page__text">Meeting our legal obligations.</li>
          </ul>
        </div>
        <div class="page__block">
          <p class="page__subtitle">Information Sharing with Service Providers</p>
          <p class="page__text">We utilize third-party credit card processors to bill you for services. These companies do not retain or share your personal information for other purposes. We only share necessary personal information with companies that assist us in service delivery, ensuring they protect your data.</p>
        </div>
        <div class="page__block">
          <p class="page__subtitle">Data Retention and Portability</p>
          <p class="page__text">We retain your information as long as your account is active or as required by law. You can request account deletion at <a class="page__link" :href="'mailto:' + companyEmail">{{ companyEmail }}</a>, and we will respond within 30 days.</p>
        </div>
        <div class="page__block">
          <p class="page__subtitle">Your Choices (Opt-Out)</p>
          <p class="page__text">{{ companyName }} will only use your personal information as described in this statement. You can opt-out of promotional communications via "My Account." Certain service-related communications cannot be opted out of. To delete your data or opt-out of further processing, contact <a class="page__link" :href="'mailto:' + companyEmail">{{ companyEmail }}</a>.</p>
        </div>
        <div class="page__block">
          <p class="page__subtitle">Protection of Your Personal Information</p>
          <p class="page__text">Your personal information is protected through various measures, including encrypted passwords and secure servers. However, no method of transmission or storage is entirely secure. If you have questions about security, reach out to us at <a class="page__link" :href="'mailto:' + companyEmail">{{ companyEmail }}</a>.</p>
        </div>
        <div class="page__block">
          <p class="page__subtitle">Cookie and Tracking Technologies</p>
          <p class="page__text">Our site may automatically collect information such as IP address, browser type, and usage statistics. We may use cookies to enhance user experience and analyze trends. You can control cookie preferences through your browser settings.</p>
        </div>
        <div class="page__block">
          <p class="page__subtitle">Changes to Privacy Policy</p>
          <p class="page__text">{{ companyName }} reserves the right to update this Privacy Policy. Material changes will be communicated via email or on the homepage before they take effect. If changes affect how your information is used, you can alter your preferences accordingly.</p>
        </div>
        <div class="page__block">
          <p class="page__subtitle">Links to Third-Party Sites</p>
          <p class="page__text">Our site may link to other websites with different privacy practices. We encourage you to read their privacy policies before submitting personal information.</p>
        </div>
        <div class="page__block">
          <p class="page__subtitle">Social Media Widgets</p>
          <p class="page__text">Our site may include social media features that collect information about your interactions. These features are governed by the privacy policies of the respective providers.</p>
        </div>
        <div class="page__block">
          <p class="page__subtitle">Data Controller</p>
          <p class="page__text">{{ companyName }}</p>
          <p class="page__text">Company Number: 15388881</p>
          <p class="page__text">167-169 Great Portland Street, 5th Floor, London, England, W1W 5PF</p>
          <p class="page__text">Email: <a class="page__link" :href="'mailto:' + companyEmail">{{ companyEmail }}</a></p>
          <p class="page__text">If you have questions about this privacy notice or how we manage your personal information, please contact our Data Protection Officer at <a class="page__link" :href="'mailto:' + companyEmail">{{ companyEmail }}</a>.</p>
        </div>
        <div class="page__block">
          <p class="page__subtitle">Privacy Complaints</p>
          <p class="page__text">Individuals in certain jurisdictions have rights regarding their personal data, including access, correction, deletion, and objection to processing. If you believe your rights are not being upheld, you can contact us at <a class="page__link" :href="'mailto:' + companyEmail">{{ companyEmail }}</a>.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useCompanyStore} from "@/stores/company.js";
import {computed} from "vue";

const companyStore = useCompanyStore()

const companyName = computed(() => {
  return companyStore.companyInfo ? companyStore.companyInfo.companyName : ''
})
const companyEmail = computed(() => {
  return companyStore.companyInfo ? companyStore.companyInfo.companyEmail : ''
})
</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.page {
  background-color: $white;
  padding-bottom: 100px;

  &__title {
    padding-block: 15px;
    background-color: rgba(238, 238, 238, 1);

    text-align: center;
    font-size: 32px;
    color: rgba(35, 56, 81, 1);
  }

  &__wrapper {
    margin-block: 50px;
    display: flex;
    flex-direction: column;
    gap: 35px;
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &__subtitle {
    font-size: 16px;
    color: rgba(35,56,81,1);
    font-weight: 700;
  }

  &__ul {
    margin-left: 25px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
    color: rgba(35,56,81,1);
  }

  &__link {
    font-weight: 700;
  }
}
</style>