<template>
  <div class="page">
    <h1 class="page__title">My Account</h1>
    <div class="container">
      <div class="page__wrapper">
        <div class="page__block">
          <div class="page__block-title">My Account</div>
          <div class="page__block-list">
            <router-link to="/account-information" class="page__block-item">
              <svg height="45" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 3.99997H6C4.89543 3.99997 4 4.8954 4 5.99997V18C4 19.1045 4.89543 20 6 20H18C19.1046 20 20 19.1045 20 18V12M18.4142 8.41417L19.5 7.32842C20.281 6.54737 20.281 5.28104 19.5 4.5C18.7189 3.71895 17.4526 3.71895 16.6715 4.50001L15.5858 5.58575M18.4142 8.41417L12.3779 14.4505C12.0987 14.7297 11.7431 14.9201 11.356 14.9975L8.41422 15.5858L9.00257 12.6441C9.08001 12.2569 9.27032 11.9013 9.54951 11.6221L15.5858 5.58575M18.4142 8.41417L15.5858 5.58575"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              Edit your account information
            </router-link>
            <router-link to="/change-password" class="page__block-item">
              <svg height="45" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_7" data-name="Layer 7">
                    <g>
                      <path d="M39,18H35V13A11,11,0,0,0,24,2H22A11,11,0,0,0,11,13v5H7a2,2,0,0,0-2,2V44a2,2,0,0,0,2,2H39a2,2,0,0,0,2-2V20A2,2,0,0,0,39,18ZM15,13a7,7,0,0,1,7-7h2a7,7,0,0,1,7,7v5H15ZM37,42H9V22H37Z"/>
                      <circle cx="15" cy="32" r="3"/>
                      <circle cx="23" cy="32" r="3"/>
                      <circle cx="31" cy="32" r="3"/>
                    </g>
                  </g>
                </g>
              </svg>
              Change your password
            </router-link>
            <router-link to="/addressbook" class="page__block-item">
              <svg fill="currentColor" height="45" viewBox="0 -2 16 16" id="id-16px" xmlns="http://www.w3.org/2000/svg">
                <path id="Path_86" data-name="Path 86" d="M-9.5,2h-15a.5.5,0,0,0-.5.5v11a.5.5,0,0,0,.5.5h15a.5.5,0,0,0,.5-.5V2.5A.5.5,0,0,0-9.5,2ZM-10,13H-24V3h14Zm-7-7.5a.5.5,0,0,1,.5-.5h5a.5.5,0,0,1,.5.5.5.5,0,0,1-.5.5h-5A.5.5,0,0,1-17,5.5Zm6,3a.5.5,0,0,1-.5.5h-4a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5h4A.5.5,0,0,1-11,8.5Zm0,3a.5.5,0,0,1-.5.5h-3a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5h3A.5.5,0,0,1-11,11.5Zm-11.5.5h5a.5.5,0,0,0,.5-.5V11a2.989,2.989,0,0,0-1.562-2.616A1.991,1.991,0,0,0-18,7a2,2,0,0,0-2-2,2,2,0,0,0-2,2,1.991,1.991,0,0,0,.562,1.384A2.99,2.99,0,0,0-23,11v.5A.5.5,0,0,0-22.5,12ZM-21,7a1,1,0,0,1,1-1,1,1,0,0,1,1,1,1,1,0,0,1-1,1A1,1,0,0,1-21,7Zm1,2a2,2,0,0,1,2,2h-4A2,2,0,0,1-20,9Z" transform="translate(25 -2)"/>
              </svg>
              Modify your address book entries
            </router-link>
            <router-link to="/wishlist" class="page__block-item">
              <svg height="45" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M12 6.00019C10.2006 3.90317 7.19377 3.2551 4.93923 5.17534C2.68468 7.09558 2.36727 10.3061 4.13778 12.5772C5.60984 14.4654 10.0648 18.4479 11.5249 19.7369C11.6882 19.8811 11.7699 19.9532 11.8652 19.9815C11.9483 20.0062 12.0393 20.0062 12.1225 19.9815C12.2178 19.9532 12.2994 19.8811 12.4628 19.7369C13.9229 18.4479 18.3778 14.4654 19.8499 12.5772C21.6204 10.3061 21.3417 7.07538 19.0484 5.17534C16.7551 3.2753 13.7994 3.90317 12 6.00019Z"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              Modify your wish list
            </router-link>
<!--            <router-link to="/balance" class="page__block-item">-->
<!--              <svg height="45" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                <path d="M15.3333 8.27316C14.4487 7.48142 13.2806 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17C13.2806 17 14.4487 16.5186 15.3333 15.7268M6 13.5H11M6 10.5H11M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"-->
<!--                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--              </svg>-->
<!--              Topup your balance-->
<!--            </router-link>-->
          </div>
        </div>
        <div class="page__block">
          <div class="page__block-title">My Orders</div>
          <div class="page__block-list">
            <router-link to="/orders" class="page__block-item">
              <svg height="45" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 11.25H16.5V12.75H10.5V11.25Z" fill="currentColor"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 7.5H16.5V9H10.5V7.5Z" fill="currentColor"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 15H16.5V16.5H10.5V15Z" fill="currentColor"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 7.5H9V9H7.5V7.5Z" fill="currentColor"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 11.25H9V12.75H7.5V11.25Z" fill="currentColor"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 15H9V16.5H7.5V15Z" fill="currentColor"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 4.5L4.5 3.75H19.5L20.25 4.5V19.5L19.5 20.25H4.5L3.75 19.5V4.5ZM5.25 5.25V18.75H18.75V5.25H5.25Z" fill="currentColor"/>
              </svg>
              View your order history
            </router-link>
            <router-link to="/downloads" class="page__block-item">
              <svg height="45" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8 10C8 7.79086 9.79086 6 12 6C14.2091 6 16 7.79086 16 10V11H17C18.933 11 20.5 12.567 20.5 14.5C20.5 16.433 18.933 18 17 18H16.9C16.3477 18 15.9 18.4477 15.9 19C15.9 19.5523 16.3477 20 16.9 20H17C20.0376 20 22.5 17.5376 22.5 14.5C22.5 11.7793 20.5245 9.51997 17.9296 9.07824C17.4862 6.20213 15.0003 4 12 4C8.99974 4 6.51381 6.20213 6.07036 9.07824C3.47551 9.51997 1.5 11.7793 1.5 14.5C1.5 17.5376 3.96243 20 7 20H7.1C7.65228 20 8.1 19.5523 8.1 19C8.1 18.4477 7.65228 18 7.1 18H7C5.067 18 3.5 16.433 3.5 14.5C3.5 12.567 5.067 11 7 11H8V10ZM13 11C13 10.4477 12.5523 10 12 10C11.4477 10 11 10.4477 11 11V16.5858L9.70711 15.2929C9.31658 14.9024 8.68342 14.9024 8.29289 15.2929C7.90237 15.6834 7.90237 16.3166 8.29289 16.7071L11.2929 19.7071C11.6834 20.0976 12.3166 20.0976 12.7071 19.7071L15.7071 16.7071C16.0976 16.3166 16.0976 15.6834 15.7071 15.2929C15.3166 14.9024 14.6834 14.9024 14.2929 15.2929L13 16.5858V11Z" fill="currentColor"/>
              </svg>
              Downloads
            </router-link>
            <router-link to="/product-return" class="page__block-item">
              <svg height="45" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                <g id="SVGRepo_iconCarrier">
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="invisible_box" data-name="invisible box">
                        <rect width="48" height="48" fill="none"></rect>
                    </g>
                    <g id="Q3_icons" data-name="Q3 icons">
                        <path d="M19.7,6a.9.9,0,0,0-.8.4L2.4,23.1a1.5,1.5,0,0,0,0,2L18.9,41.6a.9.9,0,0,0,.8.4,1.2,1.2,0,0,0,1.1-1.3V31c15.7.7,21.1,3.8,23.5,9.2.4.8.8,1.1,1.1,1.1s.6-.4.6-1c-.2-10.5-10-20.9-25.2-22.4V7.3A1.2,1.2,0,0,0,19.7,6Z"></path>
                    </g>
                  </g>
                </g>
              </svg>
              View your return requests
            </router-link>
            <router-link to="/transaction" class="page__block-item">
              <svg height="45" fill="currentColor" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                <g id="SVGRepo_iconCarrier">
                  <g>
                      <path d="M10.08,7l1,1,3.44-3.45L11,1,10,2l1.8,1.8H2v1.4h9.82ZM5.86,9l-1-1L1.42,11.5,4.91,15l1-1L4.1,12.2H14V10.8H4.1Z"></path>
                  </g>
                </g>
              </svg>
              Your Transactions
            </router-link>
          </div>
        </div>
        <div class="page__block">
          <div class="page__block-title">Newsletter</div>
          <div class="page__block-list">
            <router-link to="/newsletter" class="page__block-item">
              <svg height="45" viewBox="0 -3.5 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns">
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
                    <g id="Icon-Set-Filled" sketch:type="MSLayerGroup" transform="translate(-414.000000, -261.000000)"
                       fill="currentColor">
                        <path d="M430,275.916 L426.684,273.167 L415.115,285.01 L444.591,285.01 L433.235,273.147 L430,275.916 L430,275.916 Z M434.89,271.89 L445.892,283.329 C445.955,283.107 446,282.877 446,282.634 L446,262.862 L434.89,271.89 L434.89,271.89 Z M414,262.816 L414,282.634 C414,282.877 414.045,283.107 414.108,283.329 L425.147,271.927 L414,262.816 L414,262.816 Z M445,261 L415,261 L430,273.019 L445,261 L445,261 Z"
                              id="mail" sketch:type="MSShapeGroup">
                        </path>
                    </g>
                </g>
              </svg>
              Subscribe / unsubscribe to newsletter
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.page {
  background-color: $white;
  padding-bottom: 100px;

  &__title {
    padding-block: 15px;
    background-color: rgba(238, 238, 238, 1);

    text-align: center;
    font-size: 32px;
    color: rgba(35, 56, 81, 1);
  }

  &__wrapper {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  &__block {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &-title {
      text-transform: uppercase;
      color: $dark-blue;
      font-weight: 700;
      font-size: 15px;

      &::after {
        content: '';
        width: 50px;
        height: 3px;
        background-color: $orange;
        display: block;
        position: relative;
        margin-top: 7px;
      }
    }

    &-list {
      display: grid;
      grid-template-columns: repeat(5, 240px);
      justify-content: space-between;
    }

    &-item {
      cursor: pointer;
      width: 100%;
      border-radius: 5px;
      background: rgba(255,255,255,1);
      padding: 10px;
      border: 1px solid rgba(238,238,238,1);
      display: flex;
      flex-direction: column;
      gap: 10px;
      transition: 0.2s;

      text-align: center;
      color: #337ab7;
      font-size: 16px;
      line-height: 1.3;
      
      &:hover {
        color: $orange;
        border-color: rgba(255, 255, 255, 1);
        box-shadow: 0 0 85px -10px rgba(0,0,0,0.2);
      }
    }
  }
}

@media (max-width: 1680px) {
  .page {
    &__wrapper {
      gap: 40px;
    }

    &__block {
      &-list {
        grid-template-columns: repeat(4, 240px);
        row-gap: 25px;
      }
    }
  }
}

@media (max-width: 1050px) {
  .page {
    &__block {
      &-list {
        grid-template-columns: repeat(3, 240px);
      }
    }
  }
}

@media (max-width: 800px) {
  .page {
    &__block {
      &-list {
        grid-template-columns: repeat(2, 240px);
      }
    }
  }
}

@media (max-width: 550px) {
  .page {
    &__block {
      &-list {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
</style>