<template>
  <div class="trend">
    <div class="trend__wrapper">
      <div class="trend__left">
        <div class="trend__left-hashtag">#Trending</div>
        <router-link :to="({ name: 'store', query: { tags: 'trend' } })" class="trend__left-button">SEE ALL</router-link>
      </div>
      <div class="trend__right">
        <p class="trend__title">TRENDING CLOTHES</p>
        <Swiper
          :modules="[Autoplay]"
          :autoplay="{
            delay: 5000,
            disableOnInteraction: false,
          }"
          :space-between="20"
          :speed="1000"
          class="swiper"
          :breakpoints="{
            200: {
              slidesPerView: 1
            },
            600: {
              slidesPerView: 2
            },
            800: {
              slidesPerView: 3
            }
          }"
        >
          <Swiper-slide v-for="product in products" :key="product.node.uuid">
            <product-card
              :width="250"
              :product="product.node"
            />
          </Swiper-slide>
        </Swiper>
      </div>
    </div>
  </div>
</template>

<script setup>
import {Autoplay} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/vue";
import 'swiper/css';
import ProductCard from "@/components/product/product-card.vue";
import {onMounted, ref} from "vue";
import {Products} from "@/core/api/products.js";

const products = ref([])

const getProductsByTag = async () => {
  const res = await Products.getProductByTag('trend')
  products.value = res.data.products.edges
}

onMounted(async () => {
  await getProductsByTag()
})
</script>

<style lang="scss" scoped>
@import '@styles/global/variables';

.trend {
  padding: 100px;

  &__wrapper {
    display: flex;
    align-items: center;
    gap: 100px;
  }

  &__left {
    width: 35%;
    padding-top: 295px;
    background-image: url('@images/trendingImage.png');
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;

    &-hashtag {
      width: fit-content;
      background-color: $orange;
      padding: 5px 5px 10px 5px;

      color: $white;
      font-size: 26px;
      font-weight: 600;
    }

    &-button {
      cursor: pointer;
      background-color: $orange;
      padding: 15px 20px;
      border-radius: 3px;
      margin-bottom: 20px;
      transition: 0.2s;

      color: $white;
      font-size: 16px;
      font-weight: 700;

      &:hover {
        background-color: $light-green;
      }
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &__title {
    color: $white;
    font-weight: 700;
    font-size: 16px;

    &::after {
      content: '';
      width: 50px;
      height: 3px;
      background-color: $orange;
      display: block;
      position: relative;
      margin-top: 7px;
    }
  }
}

.swiper {
  max-width: 800px;
  width: 100% !important;
  display: block;
}

@media (max-width: 1450px) {
  .trend {
    padding-inline: 50px;

    &__wrapper {
      gap: 50px;
    }
  }
}

@media (max-width: 1350px) {
  .trend {

    &__wrapper {
      flex-direction: column;
    }

    &__left {
      width: 80%;
    }
  }
}

@media (max-width: 800px) {
  .trend {

    &__left {
      width: 100%;
    }

    &__right {
      margin-inline: 25px;
    }
  }

  .swiper {
    max-width: 600px;
  }
}

@media (max-width: 600px) {
  .swiper {
    max-width: 250px;
  }
}

@media (max-width: 450px) {
  .trend {
    padding-inline: 0;
  }
}
</style>